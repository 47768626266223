<template>
    <div :style="{background:theme.recipe_background}">
    <div class="cover-height">

    <v-img
              :src="'https://brukmat.no/images/'+this.recipeMeta.img_url"
              height="500"
              min-width="100%"
              style="position: absolute; z-index: 0;"
              alt="Oppskrift Bilde"
    ></v-img>
    <div class="cover-text" :style="{background:theme.recipe_background}">
        <v-btn
  text
  @click="$router.go(-1)"
>Tilbake</v-btn>
        <h1>{{capitalizeEveryWord(recipeMeta.title)}}</h1>
        <v-divider style="margin:14px 0px"></v-divider>
        <v-row>
            <v-col cols="6">
                Stemning <strong>{{recipeMeta.mood}}</strong>
            </v-col>
            <v-col cols="6">
                Vanskelighetsgrad <strong>{{recipeMeta.difficulty}}</strong>
            </v-col>
            <v-col cols="4">
                
            </v-col>
        </v-row>

    </div>

    </div>
    <v-divider style="z-index:1; position:relative; border-color:#e0e0e0"></v-divider>
    <v-container :style="{'z-index':1, position:'relative', background:theme.recipe_background}">
        <v-row no-gutters>
            <v-col sm="7" xs="12">
                <div class="text-h4 ma-4">Framgangsmåte</div>
                <v-timeline dense align-top v-for="(step, index) in steps" v-bind:key="index">
                    <v-timeline-item :icon="step.icon" :color="step.color" fill-dot>
                        <div v-html="step.value"></div>
                    </v-timeline-item>
                </v-timeline>
            </v-col>
            <v-spacer class="d-none d-sm-flex"></v-spacer>
            <v-divider
                class="d-none d-sm-flex"
                vertical
                ></v-divider>
            <v-col sm="4" xs="12" class="mb-4">
                <div class="text-h4 ma-4">Ingredienser</div>
                <div class="mx-4">
                    <v-text-field
                    v-model="portion"
                    :rules="[numberCheck]"
                    label="Porsjoner"
                    placeholder="4"
                    ></v-text-field>
                </div>
                <div v-for="(ingredient, index) in ingredients" v-bind:key="index+ingredient.product" class="my-1">
                <v-row>
                    
                    <v-col cols="7">
                        <v-subheader>
                            <router-link v-if="ingredient.default_ingredient.keywords.length > 0" :to="{ name: 'show-ingredient-keywords', params: { ingredient: $encodeURL(ingredient.default_ingredient.product), keywords: $joinKeywords(ingredient.default_ingredient.keywords) } }">{{ingredient.product}}</router-link>
                            <router-link v-else :to="{ name: 'show-ingredient', params: { ingredient: $encodeURL(ingredient.default_ingredient.product) } }">{{ingredient.product}}</router-link>
                            <v-chip v-for="(keyword) in ingredient.keywords" v-bind:key="keyword+ingredient.product">
                            {{keyword}}
                        </v-chip>
                        </v-subheader>
                        

                    </v-col>
                    <v-col cols="5" class="px-0">
                        <v-subheader class="px-0">
                        {{ingredient.value*portion/4}} {{ingredient.measurement}} 
                        <i>({{ingredient.amount_in_gram*portion/4}} g)</i>
                        </v-subheader>
                    </v-col>
                    
                </v-row>
                <v-divider v-if="index !== ingredients.length-1"></v-divider>
                </div>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
                <div class="text-h4 ma-4">Næringsinnhold</div>
                <v-row class="my-4">
                  <v-col xs="4" sm="2" 
                  v-for="(nutrient, nutes_index) in nutrients" v-bind:key="nutrient.title+nutes_index">
                  <div
                    class="text-center mx-2"
                  >
                  <p class="my-1">
                  {{nutrient.title}}
                  </p>
                  <v-progress-circular
                      :rotate="-90"
                      :size="80"
                      :width="12"
                      :value="nutrient.value/nutrient.multiplier"
                      :color="nutrient.color"
                    >
                      {{ strip(nutrient.value/4*portion) }} {{nutrient.measurement}}
                    </v-progress-circular>
                    </div>
                  </v-col>
                </v-row>
            </v-col>

            <v-col cols="12">
                <div id="hyvor-talk-view"></div>
                <script type="application/javascript">
                    var HYVOR_TALK_WEBSITE = 4509;
                    var HYVOR_TALK_CONFIG = {
                        url: false,
                        id: false
                    };
                </script>
                <script async type="application/javascript" src="//talk.hyvor.com/web-api/embed.js"></script>

            </v-col>
        </v-row>
        <script v-html="jsonLD" type="application/ld+json"></script>
    </v-container>
    </div>
</template>
<style scoped>
  .cover-text {
    z-index:0; 
    width: 600px; 
    min-height: 
    30px;padding: 20px;
    background: #fff; 
    position:absolute; 
    left: 50%; 
    transform: translate(-50%,0); 
    bottom:0;
    -webkit-border-radius: 30px 30px 0 0;
    border-radius: 30px 30px 0 0;
    -webkit-box-shadow: 0 0 70px 2px rgba(0,0,0,0.3);
    box-shadow: 0 0 70px 2px rgba(0,0,0,0.3);
  }
  .cover-height {
    position: relative; height:500px
  }
  @media only screen and (max-width: 600px) {
    .cover-text {
        width: 400px;
    }
    .cover-height {
        height: 450px;
    }
  }
  @media only screen and (max-width: 600){

  }
</style>
<script type="text/javascript">
    import axios from 'axios';
    export default {
        metaInfo() {
            return {
                // override the parent template and just use the above title only
                title: this.capitalizeEveryWord(this.recipeMeta.title)+ ' Oppskrift',
                // all titles will be injected into this template
                titleTemplate: '%s | Brukmat'
            }
        },
        data: () => ({
            recipeMeta:{
                title:'',
                mood:'',
                difficult:'',
                img_url:''
            },
            numberCheck:v=>!isNaN(v)||'Vennligs skriv inn et nummer',
            portion:4,
            ingredients: [],
            nutrients: [],
            steps: [],
            jsonLD: {
                "@context": "https://schema.org",
                "@type": "Recipe",
                "name":'',
                "image":'',
                "recipeIngredient": [],
                "recipeInstructions": [],
                "nutrition":[]
            }
        }),
        watch: {
            '$route': 'getRecipe'
        },
        created () {
            // fetch the data when the view is created and the data is
            // already being observed
            this.getRecipe();
        },
        computed: {
            theme(){
                return this.$vuetify.theme.themes[(this.$vuetify.theme.dark) ? 'dark' : 'light'];
            },
            // a computed getter
            jsonLDIngredients: function () {
                // `this` points to the vm instance
                console.log(this.ingredients);
                return {};
            },
            jsonLDTitle: function () {
                // `this` points to the vm instance
                console.log(this.ingredients);
                return "";
            }
        },
        methods: {
            strip(number) {
                let num = Number(number).toFixed(2);
                if (num.match(/\./)) {
                  num = num.replace(/\.?0+$/, '');
                }
                return Number(num);
            },
            getRecipe: function(){
                let recipeTitle = this.$route.params.recipe;
                // Get Recipe metadata
                axios.get('https://api.brukmat.no/recipe/?recipe='+recipeTitle)
                .then((res)=>{
                    console.log("Recipe:",res);
                    let props = res.data.properties;
                    this.recipeMeta.title = props.title;
                    this.recipeMeta.img_url = props.img_url;
                    this.jsonLD.name = props.title;
                    this.jsonLD.image = 'https://brukmat.no/img/'+props.img_url;
                    this.recipeMeta.mood = props.mood;
                    this.recipeMeta.difficulty = props.difficulty;
                }).catch((err)=>{
                    if(err) window.location.replace("/404/oppskrift");
                });
                // Get Recipe ingredients
                axios.get('https://api.brukmat.no/recipe/ingredients/?title='+recipeTitle)
                .then(async(res)=>{
                    let data = res.data;
                    let ingredients = data.map((ing)=>{
                        return {
                            "amount":ing[0].properties, 
                            "ingredient":ing[1].properties,
                            "default_ingredient":ing[2].properties
                        }
                    });
                    let formattedIng = ingredients.map((ing)=> {
                        return {
                            "product": ing.ingredient.title, 
                            "value":ing.amount.amount, 
                            "measurement":ing.amount.measurement, 
                            "amount_in_gram": ing.amount.amount_in_gram,
                            "default_ingredient":ing.default_ingredient,
                            "keywords": ing.ingredient.keywords}
                    });
                    let stringedIng = formattedIng.map((ing)=>{
                        return ing.value+ " "+ing.measurement+" "+ing.product; 
                    })
                    this.ingredients = formattedIng;
                    this.jsonLD.recipeIngredient = stringedIng;
                    let dataScheme = await this.$scheme;
                    // Let's do nutrients last as it requires the scheme
                    let formatIngWithNutrients = formattedIng.map(ing=>{
                        let unflatIng = unflattenItem(ing.default_ingredient);
                        let nutrients = unflatIng.nutrients.map(nutrient=>{
                            if(dataScheme[nutrient.title]){
                                // If hidden
                                /*if(dataScheme[nutrient.title].hide == "true"){

                                }*/
                                return {
                                  color:dataScheme[nutrient.title].color || 'grey',
                                  title:dataScheme[nutrient.title].title || nutrient.title,
                                  value:nutrient.value,
                                  multiplier:dataScheme[nutrient.title].multiplier || 1,
                                  measurement:dataScheme[nutrient.title].measurement,
                                  category:dataScheme[nutrient.title].category || 'Ukategorisert',
                                  link:dataScheme[nutrient.title].link
                                }
                              } else {
                                return {
                                  color:'grey',
                                  title:nutrient.title,
                                  value:nutrient.value,
                                  multiplier:1,
                                  category:"Ukategorisert"
                                }
                              }
                        });
                        let filteredNutes = nutrients.reduce((acc, nute)=>{
                            if(nute.category === 'Makro' && nute.title !== "kJ"){
                                acc.push(nute);
                            }
                            return acc;
                        }, []);
                        return {...ing, nutrients:filteredNutes};
                    });
                    let values = [];
                    for(let i in formatIngWithNutrients[0].nutrients){
                      var isOneUnknown = false;
                      let addedValues = formatIngWithNutrients.reduce((acc, ingredient) => {
                        if(ingredient.nutrients[i].value == -1){
                          isOneUnknown = true;
                          return acc + 0;
                        } else {
                          return acc + ingredient.nutrients[i].value/100*ingredient.amount_in_gram;
                        }
                      }, 0);
                      values.push({value:addedValues, isUnknown:isOneUnknown});
                    }
                    let finalFormat = formatIngWithNutrients[0].nutrients.map((nute, index)=>{
                        return {...nute, ...values[index]};
                    });
                    console.log("Fin", finalFormat);
                    this.nutrients = finalFormat;
                    
                    console.log("Ingredients:",formattedIng);
                });
                // Get Recipe steps
                axios.get('https://api.brukmat.no/recipe/get-steps/?title='+recipeTitle)
                .then((res)=>{
                    let properties = res.data.map(step => {
                                return {
                                    "value":step.properties.text, 
                                    "icon":step.properties.icon || 'mdi-book-open-variant',
                                    "color":"primary"};
                            }
                                );
                    properties.push({
                        "value": "<strong>Ferdig!</strong> Vel bekomme!", 
                        "icon":'mdi-check',
                        "color":"green"
                    })
                    let HowToStep = properties.map(step=>{
                        // TODO Add name
                        return {
                            "@type": "HowToStep",
                            "text": step.value
                        }
                    });
                    this.jsonLD.recipeInstructions = HowToStep;
                    this.steps = properties;
                    console.log("Steps:",properties);
                });
            }
        }
    }
    function unflattenItem(data) {
    if(data == undefined){
      return [];
    }
    if(data.length == 0){
      return [];
    }
      let result = {};
      for (var i in data) {
        var keys = i.split(/_(.+)/);
        result[i] = data[i];
        if(keys.length > 1){
          if(!result[keys[0]]) result[keys[0]] = [];

          result[keys[0]].push({"value":data[i], "title":keys[1]});
        }
      }
      if(result["nutrients"]){
        result["nutrients"] = result["nutrients"].sort((a,b) => (a.title.localeCompare(b.title)));
      }

    return result;
  }

</script>